@mixin page_title {
  position: static;
  font-size: calcSize(3);
  line-height: calcSize(4);
  margin: calcSize(0.5) 0px;
  font-weight: 700;
}

@mixin page_description {
  position: static;
  font-size: calcSize(2);
  line-height: calcSize(3);
  margin: calcSize(0.5) 0px;
  font-weight: 400;
}

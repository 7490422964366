.sd-body--responsive {
  .sd-page {
    padding: calcSize(5) var(--sd-page-vertical-padding) calcSize(2);

    &.sd-page__empty-header {
      padding-top: calcSize(3);
    }
  }
}
.sd-root-modern--mobile .sd-page {
  padding-top: calcSize(2);
}

.sd-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: calcSize(5) calcSize(2) calcSize(2);
  width: 100%;
  box-sizing: border-box;
}
.sd-page__title {
  @include page_title();
}
.sd-page__description {
  @include page_description();
}
